import React, { useState, useEffect } from 'react';
import { FaArrowUp, FaArrowDown, FaExclamationTriangle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { collection, query, where, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { addDays, addMonths } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './ProductHistory.css';
import { db } from '../services/firebaseConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setProductHistory } from '../store/dataSlice';

// Utility function to format Firestore Timestamps or date-like objects
const formatDateTime = (timestamp) => {
    let dateObj;

    // Check if timestamp is a Firestore Timestamp or a Date object, otherwise convert it
    if (timestamp && typeof timestamp.toDate === 'function') {
        dateObj = timestamp.toDate();
    } else if (typeof timestamp === 'object' && timestamp.seconds) {
        // If it's an object with seconds and nanoseconds (Firestore Timestamp-like)
        dateObj = new Date(timestamp.seconds * 1000);
    } else {
        // If it's a plain Date object or ISO string
        dateObj = new Date(timestamp);
    }

    const date = dateObj.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });
    const time = dateObj.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    return { date, time };
};

function ProductHistory({ inventoryItemId }) {
    const dispatch = useDispatch();
    const productHistoryFromStore = useSelector(state => state.data.productHistory?.[inventoryItemId] || null);

    const [historyItems, setHistoryItems] = useState([]);
    const [expandedSections, setExpandedSections] = useState({});
    const [dateFilter, setDateFilter] = useState({ start: '', end: '' });
    const [selectedButton, setSelectedButton] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!inventoryItemId) return;

        // If product history is already in Redux, use that
        if (productHistoryFromStore) {
            setHistoryItems(productHistoryFromStore);
            return;
        }

        setIsLoading(true);
        const now = new Date();
        const sevenDaysAgo = addDays(now, -7);

        // Use dateFilter if set, otherwise default to last 7 days
        const start = dateFilter.start || sevenDaysAgo;
        const end = dateFilter.end || now;

        // Set up the Firestore snapshot listener
        const unsubscribe = onSnapshot(
            query(
                collection(db, 'inventory_updates'),
                where('inventoryItemId', '==', inventoryItemId),
                where('timestamp', '>=', start),
                where('timestamp', '<=', end),
                orderBy('timestamp', 'desc'),
                limit(10)
            ),
            (snapshot) => {
                const historyData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const { date, time } = formatDateTime(data.timestamp);

                    return {
                        id: doc.id,
                        ...data,
                        date,  // Formatted date
                        time,  // Formatted time
                    };
                });

                setHistoryItems(historyData);
                setIsLoading(false);

                // Store in Redux
                dispatch(setProductHistory({
                    inventoryItemId,
                    historyData,
                }));
            },
            (error) => {
                console.error('Error fetching real-time product history:', error);
                setIsLoading(false);
            }
        );

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, [inventoryItemId, dateFilter, dispatch, productHistoryFromStore]);


    const toggleSection = (sectionName) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionName]: !prev[sectionName]
        }));
    };


    return (
        <div className="product-history-card">
            <div className="header">
                {/* Preset Date Buttons */}
                <div className="preset-dates">
                    <button
                        onClick={() => {
                            setDateFilter({ start: addDays(new Date(), -7), end: new Date() });
                            setSelectedButton(0);
                        }}
                        className={selectedButton === 0 ? 'selected' : ''}
                    >
                        Last 7 Days
                    </button>
                    <button
                        onClick={() => {
                            const now = new Date();
                            const start = new Date(now.getFullYear(), now.getMonth(), 1); // First day of this month
                            const end = now; // Today
                            setDateFilter({ start, end });
                            setSelectedButton(1);
                        }}
                        className={selectedButton === 1 ? 'selected' : ''}
                    >
                        This Month
                    </button>
                    <button
                        onClick={() => {
                            const now = new Date();
                            const start = new Date(now.getFullYear(), now.getMonth() - 1, 1); // First day of last month
                            const end = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of last month
                            setDateFilter({ start, end });
                            setSelectedButton(2);
                        }}
                        className={selectedButton === 2 ? 'selected' : ''}
                    >
                        Last Month
                    </button>
                    <button
                        onClick={() => {
                            setDateFilter({ start: addMonths(new Date(), -3), end: new Date() });
                            setSelectedButton(3);
                        }}
                        className={selectedButton === 3 ? 'selected' : ''}
                    >
                        Last 3 Months
                    </button>
                    <button
                        onClick={() => {
                            setDateFilter({ start: addMonths(new Date(), -6), end: new Date() });
                            setSelectedButton(4);
                        }}
                        className={selectedButton === 4 ? 'selected' : ''}
                    >
                        Last 6 Months
                    </button>
                    <button
                        onClick={() => {
                            const now = new Date();
                            const start = new Date(now.getFullYear() - 1, 0, 1); // January 1st of last year
                            const end = new Date(now.getFullYear() - 1, 11, 31); // December 31st of last year
                            setDateFilter({ start, end });
                            setSelectedButton(5);
                        }}
                        className={selectedButton === 5 ? 'selected' : ''}
                    >
                        Last Year
                    </button>
                    <button
                        onClick={() => {
                            const now = new Date();
                            const start = new Date(now.getFullYear(), 0, 1); // January 1st of this year
                            const end = now; // Today
                            setDateFilter({ start, end });
                            setSelectedButton(6);
                        }}
                        className={selectedButton === 6 ? 'selected' : ''}
                    >
                        This Year
                    </button>
                </div>

            </div>

            {
                isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="grouped-history">
                        <div className="group-section">
                            <div className="group-header" onClick={() => toggleSection('Stock Movements')}>
                                <h2>Stock Movements</h2>
                                <span>{expandedSections['Stock Movements'] ? '-' : '+'}</span>
                            </div>
                            <div className="product-history-item-container">
                                {expandedSections['Stock Movements'] &&
                                    historyItems
                                        .filter(item => item.updateType === 'Stock In' || item.updateType === 'Stock Out')
                                        .map(item => {
                                            const { date, time } = formatDateTime(item.timestamp);
                                            const isStockIn = item.updateType === 'Stock In';
                                            const itemClassName = isStockIn ? 'stock-in' : 'stock-out';
                                            return (
                                                <div key={item.id} className={`product-history-item ${itemClassName}`}>
                                                    <div className="product-history-header">
                                                        <div className={`product-history-icon-container ${itemClassName}`}>
                                                            {isStockIn ? <FaArrowUp /> : <FaArrowDown />}
                                                        </div>
                                                        <div className="product-history-info">
                                                            <span className={`product-history-type ${itemClassName}`}>{item.updateType}</span>
                                                            <span className="product-history-date">
                                                                <span>{date}</span>
                                                                <span>{time}</span>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="product-history-details">
                                                        <p><b>{item.quantityChange} </b>items {isStockIn ? 'added to' : 'withdrawn from'} the stock</p>
                                                        <p className='unpadded-text'>Reference: {item.details}</p>
                                                    </div>

                                                    <div className="product-history-divider"></div>
                                                    <p className="updated-quantity">Updated quantity: <strong>{item.newQuantity}</strong></p>
                                                </div>
                                            );
                                        })}
                            </div>
                        </div>

                        <div className="group-section">
                            <div className="group-header-alert" onClick={() => toggleSection('Other Alerts')}>
                                <h2>Other Alerts</h2>
                                <span>{expandedSections['Other Alerts'] ? '-' : '+'}</span>
                            </div>
                            <div className="product-history-item-container">
                                {expandedSections['Other Alerts'] &&
                                    historyItems
                                        .filter(item => item.updateType !== 'Stock In' && item.updateType !== 'Stock Out')
                                        .map(item => {
                                            const { date, time } = formatDateTime(item.timestamp);

                                            // If Low stock, red-text, if Reorder level reached, orange-text, all else, and otyhere-alert
                                            const itemClassName = item.updateType === 'Low stock' ? 'red-text' : item.updateType === 'Reorder level reached' ? 'orange-text' : 'other-alert';
                                            return (
                                                <div key={item.id} className={`product-history-item ${itemClassName}`}>
                                                    <div className="product-history-header">
                                                        <div className={`product-history-icon-container ${itemClassName}`}>
                                                            <FaExclamationTriangle />
                                                        </div>
                                                        <div className="product-history-info">
                                                            <span className={`product-history-type ${itemClassName}`}>{item.updateType}</span>
                                                            <span className="product-history-date">
                                                                <span>{date}</span>
                                                                <span>{time}</span>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="product-history-details">
                                                        <p className='padded-text'>{item.details}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                            </div>

                            {historyItems.length === 0 && (
                                <div className="no-history">
                                    <p>No history available for the selected date range</p>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </div >
    );
}

ProductHistory.propTypes = {
    inventoryItemId: PropTypes.string,
    onClose: PropTypes.func,
};

export default ProductHistory;
