import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, TextField, Avatar, Select, MenuItem } from '@mui/material';
import { Search } from '@mui/icons-material';
import { setSelectedBusiness as setSelectedBusinessAction } from '../store/dataSlice';
import { logout } from '../services/authService';

function TopBar({ businesses, selectedBusinessId, onBusinessChange, onSearch }) {
    const [localSelectedBusiness, setLocalSelectedBusiness] = useState(selectedBusinessId || '');
    const [selectedBusinessName, setSelectedBusinessName] = useState('Select a Business');
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    useEffect(() => {
        // Update selected business if the default is passed in
        if (selectedBusinessId) {
            setLocalSelectedBusiness(selectedBusinessId);
            dispatch(setSelectedBusinessAction(selectedBusinessId));
        }
    }, [selectedBusinessId, dispatch]);

    useEffect(() => {
        // Update the business name based on the selected ID
        const currentBusiness = businesses.find((business) => business.id === localSelectedBusiness);
        if (currentBusiness) {
            setSelectedBusinessName(currentBusiness.businessName);
        } else {
            setSelectedBusinessName('Select a Business');
        }
    }, [localSelectedBusiness, businesses]);

    const handleBusinessChange = (event) => {
        const businessId = event.target.value;
        setLocalSelectedBusiness(businessId);
        dispatch(setSelectedBusinessAction(businessId));

        if (onBusinessChange) {
            onBusinessChange(businessId);
        }
    };

    // State to handle dropdown visibility
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    // Function to handle user logout
    const handleLogout = async () => {
        try {
            await logout();
            console.log('User logged out successfully');
        } catch (error) {
            console.error('Logout failed:', error);
            alert('An error occurred during logout. Please try again.');
        }
    };

    return (
        <div className="top-bar">
            {/* Logo */}
            <img
                src="https://pocketiapp.com/wp-content/uploads/2024/08/stockit_logo.png"
                alt="Logo"
                className="logo"
            />

            {/* Business Selector */}
            <Select
                value={localSelectedBusiness}
                onChange={handleBusinessChange}
                displayEmpty
                className="business-selector"
                variant="outlined"
                size="small"
            >
                <MenuItem value="" disabled>
                    {selectedBusinessName}
                </MenuItem>
                {businesses.map((business) => (
                    <MenuItem key={business.id} value={business.id} className="dropdown-container">
                        {business.businessName}
                    </MenuItem>
                ))}
            </Select>

            {/* Search Input */}
            <div className="search-container">
                <IconButton>
                    <Search />
                </IconButton>
                <TextField
                    placeholder="Search"
                    onChange={(e) => onSearch(e.target.value)}
                    variant="outlined"
                    size="small"
                />
            </div>

            {/* Profile Section */}
            <div
                className="profile-section"
                onMouseEnter={() => setIsDropdownVisible(true)}
                onMouseLeave={() => setIsDropdownVisible(false)}
            >
                <p>Welcome, {user?.displayName || user?.full_name || 'User'}</p>

                <div className="profile-avatar" style={{ cursor: 'pointer' }}>
                    <Avatar
                        alt="User Profile"
                        src={
                            user?.photoURL ||
                            process.env.PUBLIC_URL + '/default_avatar.png' // default avatar image from public folder
                        }
                    />
                </div>
                {/* Dropdown for logout */}
                {isDropdownVisible && (
                    <div
                        className="profile-dropdown"
                        onMouseEnter={() => setIsDropdownVisible(true)}
                        onMouseLeave={() => setIsDropdownVisible(false)}
                    >
                        <button className="logout-button" onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TopBar;
