import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from './firebaseConfig';

export const fetchUserData = (user, onDataUpdate) => {
  try {
    // Query to get businesses where userId matches the logged-in user's uid
    const businessesQuery = query(
      collection(db, 'businesses'),
      where('userId', '==', user.uid),
      where('deleted', '==', false)
    );

    // Query to get inventory items where userEmail matches the logged-in user's email
    const inventoryQuery = query(
      collection(db, 'inventory'),
      where('userEmail', '==', user.email),
      where('deleted', '==', false)
    );

    //fetch inventory_update and setHistoryItems
    // const inventoryUpdateQuery = query(
    //   collection(db, 'inventory_updates'),
    //   where('userId', '==', user.uid),

      
    // );


    // Set up real-time listeners
    const unsubscribeBusinesses = onSnapshot(businessesQuery, (snapshot) => {
      const businesses = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      onDataUpdate({ type: 'businesses', data: businesses });
    });

    const unsubscribeInventory = onSnapshot(inventoryQuery, (snapshot) => {
  const inventory = snapshot.docs.map(doc => ({
    inventoryItemId: doc.id,  // Store the Firestore document ID as inventoryItemId
    ...doc.data(),
  }));
  
  onDataUpdate({ type: 'inventory', data: inventory });
});

    // const unsubscribeInventoryUpdate = onSnapshot(inventoryUpdateQuery, (snapshot) => {
    //   const inventoryUpdate = snapshot.docs.map(doc => ({
    //     inventoryUpdateId: doc.id,  // Store the Firestore document ID as inventoryItemId
    //     ...doc.data(),
    //   }));
      
    //   onDataUpdate({ type: 'inventory_update', data: inventoryUpdate });
    // });

    // Return unsubscribe functions to stop listening when needed
    return () => {
      unsubscribeBusinesses();
      unsubscribeInventory();
      // unsubscribeInventoryUpdate();
    };
  } catch (error) {
    console.error("Error fetching user data: ", error);
    return null;
  }
};
