import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businesses: [],
  inventory: [],
  productHistory: {},
  categories: [],
  selectedBusiness: null,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setBusinesses: (state, action) => {
      state.businesses = action.payload;
    },
    setInventory: (state, action) => {
      state.inventory = action.payload;
    },
    setProductHistory: (state, action) => {
      const { inventoryItemId, historyData } = action.payload;
      state.productHistory = {
        ...state.productHistory,
        [inventoryItemId]: historyData,
      };
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSelectedBusiness: (state, action) => {
      state.selectedBusiness = action.payload;
    },
    clearData: (state) => {
      state.businesses = [];
      state.inventory = [];
    },
  },
});

export const {
  setBusinesses,
  setInventory,
  clearData,
  setCategories,
  setSelectedBusiness,
  setProductHistory,
} = dataSlice.actions;

export default dataSlice.reducer;
