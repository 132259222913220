import React, { useState } from 'react';
import DetailView from './DetailView';

function GridView({ items }) {
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 12; // Set the number of cards to display per page

    const handleCardClick = (item) => {
        setSelectedItem(item);
    };

    const closeDetailView = () => {
        setSelectedItem(null);
    };

    // Sort the full list by dateAdded before pagination
    const sortedItems = items.slice().sort((a, b) => b.dateAdded.seconds - a.dateAdded.seconds);


    // Pagination Logic
    const totalPages = Math.ceil(items.length / cardsPerPage);
    const startIndex = (currentPage - 1) * cardsPerPage;
    const currentItems = sortedItems.slice(startIndex, startIndex + cardsPerPage);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div>
            <div className="grid-view">
                <div className="grid-cards">
                    {currentItems.map((item, index) => (
                        <div key={index} className="grid-card" onClick={() => handleCardClick(item)}>
                            <h3>{item.productName}</h3>
                            <p>SKU: {item.sku}</p>
                            <p className='bold-text'>Quantity: <strong className='green-text'>{item.initialQuantity}</strong> {item.shortUnit}</p>
                            <p className='category-item'> {item.category}</p>
                        </div>
                    ))}
                </div>



                {/* Detailed view section */}
                <DetailView item={selectedItem} onClose={closeDetailView} />
            </div>
            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous &laquo;
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next &raquo;
                </button>
            </div>
        </div>
    );
}

export default GridView;
