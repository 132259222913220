// src/routes/PrivateRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector } from 'react-redux';
import { auth } from '../services/firebaseConfig';
import { Button, Card, CardContent, Typography } from '@mui/material';
import './PrivateRoute.css';


function PrivateRoute({ children }) {
    const [user, loading] = useAuthState(auth);
    const subscriptionTier = useSelector((state) => state.auth.subscriptionTier);
    const [showMessage, setShowMessage] = React.useState(false);

    if (loading) {
        return <div>Loading...</div>;
    }

    // Show message before redirecting for non-subscribed users
    if (!user || subscriptionTier === 'free') {
        if (!showMessage) {
            setShowMessage(true);
            setTimeout(() => setShowMessage(false), 3000); // Show message for 3 seconds
        }

        return showMessage ? (
            <div className="subscription-message-container">
                {/* logo */}
                <Card className="subscription-card">
                    <CardContent>
                        <Typography variant="h5" component="div" gutterBottom>
                            Subscription Required
                        </Typography>
                        <Typography variant="body1">
                            This application is available to subscribed users only. Please subscribe to one of our plans on <a href='https://go.pocketi.app/stockit'>the app</a> to enjoy full access.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.location.href = '/login'}
                            style={{ marginTop: '1rem' }}
                            className='subscription-button'
                        >
                            Subscribed? Login here
                        </Button>
                    </CardContent>
                </Card>
            </div>
        ) : (
            <Navigate to="/login" />
        );
    }

    return children;
}

export default PrivateRoute;
