import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './StockTrendsChart.css';

function StockTrendsChart({ data }) {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" className='chart-text' />
                <XAxis dataKey="productName" className='chart-text' />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="totalStockInQuantity" fill="#38B635" name="Stock In" radius={[12, 12, 0, 0]} />
                <Bar dataKey="totalStockOutQuantity" fill="#FF9537" name="Stock Out" radius={[12, 12, 0, 0]} />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default StockTrendsChart;
