// src/services/authService.js
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider, 
  OAuthProvider 
} from 'firebase/auth';

const auth = getAuth();

// Login with Email and Password
export const emailLogin = async (email, password) => {
try {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  return userCredential.user;
} catch (error) {
  return error; // Return error if login fails
}
};

// Google Login
export const googleLogin = async () => {
try {
  const provider = new GoogleAuthProvider();
  const userCredential = await signInWithPopup(auth, provider);
  return userCredential.user; // Return user on success
} catch (error) {
  return error; // Return error if login fails
}
};

// Apple Login
export const appleLogin = async () => {
try {
  const provider = new OAuthProvider('apple.com');
  const userCredential = await signInWithPopup(auth, provider);
  return userCredential.user; // Return user on success
} catch (error) {
  return error; // Return error if login fails
}
};

// Logout
export const logout = async () => {
try {
  await auth.signOut();
  //return to logout page
  window.location.href = '/';
} catch (error) {
  return error; // Return error if logout fails
}
};

// Get Current User
export const getCurrentUser = () => {
return auth.currentUser;
};