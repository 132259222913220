// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authReducer from './authSlice';
import dataReducer from './dataSlice';

// Persist configuration for auth
const authPersistConfig = {
  key: 'auth',
  storage,
};

// Persist configuration for data
const dataPersistConfig = {
  key: 'data',
  storage,
};

// Create persisted reducers for both slices
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedDataReducer = persistReducer(dataPersistConfig, dataReducer);

// Configure store with separate reducers
export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    data: persistedDataReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(
    {
      serializableCheck: false,
    },
  ),
});

// Create a persistor to be used for persisting the store
export const persistor = persistStore(store);
