// src/components/DashboardOverview.jsx
import React from 'react';
import InventoryIcon from '@mui/icons-material/Inventory'; // Example icon
import WarningIcon from '@mui/icons-material/Warning';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

function DashboardOverview({ summaryData }) {

    // Define icons for each label
    const iconMap = {
        'Out of Stock': <WarningIcon className="card-icon-red" />,
        'Total Items': <InventoryIcon className="card-icon-green" />,
        'Low Stock Alerts': <TrendingUpIcon className="card-icon-orange" />,
        'Expired Items': <WarningIcon className="card-icon-red" />,
    };
    return (
        <div className="dashboard-overview">
            {summaryData.map((item) => (
                <div key={item.label} className="summary-card">
                    <div className="card-header">
                        <h3>{item.label}</h3>
                        <div className="card-icon">
                            {iconMap[item.label]} {/* Icon in the top-right */}
                        </div>
                    </div>
                    <h1>{item.value}</h1>
                </div>
            ))}
        </div>
    );
}

export default DashboardOverview;
