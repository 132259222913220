import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../services/firebaseConfig';
import './ReportingPanel.css';
import { setProductHistory } from '../store/dataSlice';
import { FaAngleDoubleDown, FaAngleDoubleUp, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import StockTrendsChart from './StockTrendsChart'; // Import the StockTrendsChart component

function ReportingPanel({ items, reports }) {
    const [activeTab, setActiveTab] = useState(reports[0]?.title);
    const [filteredItems, setFilteredItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [timeRange, setTimeRange] = useState('This Month'); // New state for time range filter
    const productHistoryFromStore = useSelector((state) => state.data.productHistory || []);
    const selectedBusiness = useSelector((state) => state.data.selectedBusiness);
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const selectedBusinessName = useSelector((state) => {
        const business = state.data.businesses.find((business) => business.id === state.data.selectedBusiness);
        return business ? business.businessName : '';
    });

    // Handle tab click
    const handleTabClick = (title) => {
        setActiveTab(title);
        setCurrentPage(1);
        setIsLoading(true);
    };

    // Handle time range change
    const handleTimeRangeChange = (e) => {
        setTimeRange(e.target.value);
        setIsLoading(true);
    };

    // Fetch product history from Firestore if not in store
    const fetchProductHistory = async () => {
        if (productHistoryFromStore.length > 0) {
            return productHistoryFromStore;
        }
        try {
            const q = query(
                collection(db, 'inventory_updates'),
                where('businessId', '==', selectedBusiness)
            );
            const querySnapshot = await getDocs(q);
            const historyItems = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            dispatch(setProductHistory(historyItems));
            return historyItems;
        } catch (error) {
            console.error('Error fetching product history:', error);
            return [];
        }
    };

    // Calculate date range based on selected time range
    const getDateRange = () => {
        const now = new Date();
        let startDate;
        let endDate;

        switch (timeRange) {
            case 'This Week':
                // Get a copy of `now` to avoid modifying the original date
                const currentDate = new Date();
                startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - currentDate.getDay()); // Start of the week (Sunday)
                endDate = new Date(); // Current date
                break;

            case 'Last Week':
                // Create a new date object for calculating last week
                const lastWeekDate = new Date();
                const lastWeekStart = new Date(lastWeekDate);
                lastWeekStart.setDate(lastWeekDate.getDate() - lastWeekDate.getDay() - 7); // Start of the previous week (Sunday)
                const lastWeekEnd = new Date(lastWeekDate);
                lastWeekEnd.setDate(lastWeekDate.getDate() - lastWeekDate.getDay() - 1); // End of the previous week (Saturday)

                startDate = lastWeekStart;
                endDate = lastWeekEnd;
                break;

            case 'This Month':
                startDate = new Date(now.getFullYear(), now.getMonth(), 1); // Start of the current month
                endDate = new Date(); // Current date
                break;
            case 'This Year':
                startDate = new Date(now.getFullYear(), 0, 1); // Start of the current year (Jan 1)
                endDate = new Date(); // Current date
                break;
            case 'Last Year': // Jan 1 to Dec 31 of the previous year
                startDate = new Date(now.getFullYear() - 1, 0, 1); // Jan 1 of last year
                endDate = new Date(now.getFullYear() - 1, 11, 31); // Dec 31 of last year
                break;
            case 'Last Month':
                startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1); // Start of the last month
                endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the last month
                break;
            default:
                startDate = new Date(now.getFullYear(), now.getMonth(), 1); // Default to this month
                endDate = new Date(); // Current date
        }

        return { startDate, endDate };

    };

    // Effect to fetch and filter data based on active tab
    useEffect(() => {
        const filterData = async () => {
            if (!selectedBusiness) {
                console.warn('No business selected');
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            const historyItems = await fetchProductHistory();

            if (!historyItems) {
                setFilteredItems([]);
                setIsLoading(false);
                return;
            }

            const { startDate, endDate } = getDateRange();
            const filteredByDate = historyItems.filter(
                (item) => item.timestamp.toDate() >= startDate && item.timestamp.toDate() <= endDate
            );

            let filteredData = [];

            if (activeTab === 'Stock In Report') {
                filteredData = filteredByDate
                    .filter((historyItem) => historyItem.updateType === 'Stock In')
                    .map((historyItem) => {
                        const matchingItem = items.find(
                            (item) => item.inventoryItemId === historyItem.inventoryItemId
                        );
                        return {
                            ...matchingItem,
                            ...historyItem,
                        };
                    })
                    .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
            } else if (activeTab === 'Stock Out Report') {
                filteredData = filteredByDate
                    .filter((historyItem) => historyItem.updateType === 'Stock Out')
                    .map((historyItem) => {
                        const matchingItem = items.find(
                            (item) => item.inventoryItemId === historyItem.inventoryItemId
                        );
                        return {
                            ...matchingItem,
                            ...historyItem,
                        };
                    })
                    .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
            } else if (activeTab === 'Out of Stock Items') {
                filteredData = items.filter((item) => item.initialQuantity === 0 || item.initialQuantity === undefined);
            }

            else if (activeTab === 'Reorder Report') {

                filteredData = items.filter((item) => item.initialQuantity <= item.reorderQuantity);
            }
            else if (activeTab === 'Slow Moving Items') {
                filteredData = items
                    .map((item) => {
                        // Calculate the total stock-out count for each item
                        const stockOutCount = filteredByDate.filter(
                            (historyItem) =>
                                historyItem.inventoryItemId === item.inventoryItemId && historyItem.updateType === 'Stock Out'
                        ).length;

                        return {
                            productName: item.productName,
                            stockOutCount,
                        };
                    })
                    .filter((item) => item.stockOutCount > 0) // Ensure only items with stock-outs are shown
                    .sort((a, b) => b.stockOutCount - a.stockOutCount); // Sort by stock-out count
            }



            else if (activeTab === 'Fast Moving Items') {
                filteredData = items
                    .map((item) => {
                        // Calculate the total quantityChange for stock-outs for each item
                        const totalQuantityChange = filteredByDate
                            .filter(
                                (historyItem) =>
                                    historyItem.inventoryItemId === item.inventoryItemId &&
                                    historyItem.updateType === 'Stock Out'
                            )
                            .reduce((sum, historyItem) => sum + (historyItem.quantityChange || 0), 0); // Sum of quantityChange

                        return {
                            productName: item.productName,
                            totalStockOutQuantity: totalQuantityChange,
                        };
                    })
                    .filter((item) => item.totalStockOutQuantity > 0) // Ensure only items with stock-out are shown
                    .sort((a, b) => b.totalStockOutQuantity - a.totalStockOutQuantity); // Sort by total quantity
            }





            else if (activeTab === 'Expiry Report') {
                // Filter items where expiry date is in the past
                filteredData = items.filter((item) => {
                    // Check if expiryDate exists and convert it to a JavaScript Date object
                    if (item.expiryDate && item.expiryDate.seconds) {
                        const expiryDate = new Date(item.expiryDate.seconds * 1000);
                        return expiryDate <= new Date(); // Check if the expiry date is before or equal to today
                    }
                    return false; // If there's no expiryDate, exclude the item
                });
            }

            //Stock Trends Bar Chart of all items with Stock In and Stock Out sum of quantity (quantityChange)
            else // Inside your ReportingPanel component, after filteredData is created
                if (activeTab === 'Stock Trends') {
                    filteredData = items.map((item) => {
                        const totalStockInQuantity = filteredByDate
                            .filter((historyItem) =>
                                historyItem.inventoryItemId === item.inventoryItemId && historyItem.updateType === 'Stock In'
                            )
                            .reduce((sum, historyItem) => sum + (historyItem.quantityChange || 0), 0);

                        const totalStockOutQuantity = filteredByDate
                            .filter((historyItem) =>
                                historyItem.inventoryItemId === item.inventoryItemId && historyItem.updateType === 'Stock Out'
                            )
                            .reduce((sum, historyItem) => sum + (historyItem.quantityChange || 0), 0);

                        return {
                            productName: item.productName,
                            totalStockInQuantity,
                            totalStockOutQuantity,
                        };
                    })
                        .filter((item) => item.totalStockInQuantity > 0 || item.totalStockOutQuantity > 0)
                        .sort((a, b) => b.totalStockInQuantity - a.totalStockInQuantity);

                    // Set state only if filteredData is different to prevent unnecessary updates
                    if (JSON.stringify(filteredItems) !== JSON.stringify(filteredData)) {
                        setFilteredItems(filteredData);
                    }

                    setIsLoading(false);
                    return; // Remove the JSX return from here
                }

            setFilteredItems(filteredData);
            setIsLoading(false);
        };

        filterData();
    }, [activeTab, items, productHistoryFromStore, selectedBusiness]); // Ensure necessary dependencies only



    // Pagination logic
    const totalPages = Math.ceil(filteredItems.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const currentItems = filteredItems.slice(startIndex, startIndex + rowsPerPage);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Export PDF function
    const handleExportPDF = () => {
        const doc = new jsPDF({ orientation: 'landscape' });
        doc.text(`${activeTab} - ${selectedBusinessName}`, 10, 10);

        let headers = [];
        let data = [];

        if (activeTab === 'Reorder Report') {
            headers = [['Product Name', 'Available Quantity', 'Reorder Quantity']];
            data = filteredItems.map((item) => [
                item.productName || 'N/A',
                item.initialQuantity || 'N/A',
                item.reorderQuantity || 'N/A',
            ]);
        } else if (activeTab === 'Out of Stock Items') {
            headers = [['Product Name', 'Available Quantity']];
            data = filteredItems.map((item) => [
                item.productName || 'N/A',
                item.initialQuantity !== undefined ? item.initialQuantity : '0',
            ]);



        } else if (activeTab === 'Slow Moving Items') {
            headers = [['Product Name', 'Stock Out Count']];
            data = filteredItems.map((item) => [
                item.productName || 'N/A',
                item.stockOutCount || '0',
            ]);
        }
        else if (activeTab === 'Fast Moving Items' || activeTab === 'Slow Moving Items') {
            data = filteredItems.map((item) => ({
                'Product Name': item.productName || 'N/A',
                'Total Stock Out Quantity': item.totalStockOutQuantity || 'N/A',
            })).filter((item) => item['Total Stock Out Quantity'] > 0); // Optional: Filter out items with no stock-out quantity
        }


        else if (activeTab === 'Expiry Report') {
            headers = [['Product Name', 'Expiry Date']];
            data = filteredItems.map((item) => [
                item.productName || 'N/A',
                item.expiryDate
                    ? new Date(item.expiryDate.seconds * 1000).toLocaleDateString()
                    : 'N/A',
            ]);
        }

        else if (activeTab === 'Stock Trends') {
            //pdf as per stock trends chart
            doc.text(`${activeTab} - ${selectedBusinessName}`, 10, 10);
            doc.autoTable({
                startY: 20,
                head: [['Product Name', 'Total Stock In Quantity', 'Total Stock Out Quantity']],
                body: filteredItems.map((item) => [
                    item.productName || 'N/A',
                    item.totalStockInQuantity || 'N/A',
                    item.totalStockOutQuantity || 'N/A',
                ]),
            });
            doc.save(`${activeTab}-${selectedBusinessName}.pdf`);
            return;
        }


        else {
            headers = [['Product Name', 'Quantity', 'Details', 'Action Type', 'Date']];
            data = filteredItems.map((item) => [
                item.productName || 'N/A',
                item.quantityChange || 'N/A',
                item.details || 'N/A',
                item.updateType || 'N/A',
                item.timestamp
                    ? new Date(item.timestamp.seconds * 1000).toLocaleDateString()
                    : item.dateAdded
                        ? new Date(item.dateAdded.seconds * 1000).toLocaleDateString()
                        : 'N/A',
            ]);
        }

        doc.autoTable({
            startY: 20,
            head: headers,
            body: data,
        });
        doc.save(`${activeTab}-${selectedBusinessName}.pdf`);
    };



    // Export Excel function
    const handleExportExcel = () => {
        let data = [];

        if (activeTab === 'Reorder Report') {
            data = filteredItems.map((item) => ({
                'Product Name': item.productName || 'N/A',
                'Available Quantity': item.initialQuantity || 'N/A',
                'Reorder Quantity': item.reorderQuantity || 'N/A',
            }));
        } else if (activeTab === 'Out of Stock Items') {
            data = filteredItems.map((item) => ({
                'Product Name': item.productName || 'N/A',
                'Available Quantity': item.initialQuantity || 'N/A',
            }));
        } else if (activeTab === 'Fast Moving Items') {
            data = filteredItems.map((item) => ({
                'Product Name': item.productName || 'N/A',
                'Total Stock Out Quantity': item.totalStockOutQuantity || 'N/A',
            })).filter((item) => item['Total Stock Out Quantity'] > 0);
        }



        //expirty report
        else if (activeTab === 'Expiry Report') {
            data = filteredItems.map((item) => ({
                'Product Name': item.productName || 'N/A',
                'Expiry Date': item.expiryDate
                    ? new Date(item.expiryDate.seconds * 1000).toLocaleDateString()
                    : 'N/A',
            }));
        }


        //Stock Trends Chart
        else if (activeTab === 'Stock Trends') {
            data = filteredItems.map((item) => ({
                'Product Name': item.productName || 'N/A',
                'Total Stock In Quantity': item.totalStockInQuantity || 'N/A',
                'Total Stock Out Quantity': item.totalStockOutQuantity || 'N/A',
            })).filter((item) => item['Total Stock In Quantity'] > 0 || item['Total Stock Out Quantity'] > 0);
        }
        else {
            data = filteredItems.map((item) => ({
                'Product Name': item.productName || 'N/A',
                Quantity: item.quantityChange || 'N/A',
                Details: item.details || 'N/A',
                'Action Type': item.updateType || 'N/A',
                Date: item.timestamp
                    ? new Date(item.timestamp.seconds * 1000).toLocaleDateString()
                    : item.dateAdded
                        ? new Date(item.dateAdded.seconds * 1000).toLocaleDateString()
                        : 'N/A',
            }));
        }


        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, activeTab);
        XLSX.writeFile(workbook, `${activeTab}-${selectedBusinessName}.xlsx`);
    };


    return (
        <div className="reporting-panel">

            <div className='reporting-panel-header'>
                <button onClick={() => setIsOpen(!isOpen)} className="report-button">
                    View Reports {isOpen ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />}
                </button>
                <select value={timeRange} onChange={handleTimeRangeChange} className="time-filter">

                    <option value="This Year">This Year</option>
                    <option value="This Week">This Week</option>
                    <option value="Last Week">Last Week</option>
                    <option value="This Month">This Month</option>
                    <option value="Last Month">Last Month</option>
                    <option value="Last Year">Last Year</option>
                </select>
            </div>
            {isOpen && (
                <div>
                    <div className="tabs">
                        {reports.map((report) => (
                            <button
                                key={report.title}
                                className={`tab-button ${activeTab === report.title ? 'active' : ''}`}
                                onClick={() => handleTabClick(report.title)}
                            >
                                {report.title}
                            </button>
                        ))}

                    </div>
                    {/* Tab Content */}
                    <div className="tab-content">
                        {isLoading ? (
                            <p>Loading...</p>
                        ) : (
                            reports.map(
                                (report) =>
                                    report.title === activeTab && (
                                        <div key={report.title} className="report-content">
                                            <h4>
                                                {report.title} - <span className="red-text">{selectedBusinessName}</span>
                                            </h4>
                                            <>
                                                {activeTab === 'Stock Trends' ? (
                                                    // Render the StockTrendsChart when the active tab is "Stock Trends"
                                                    <StockTrendsChart data={filteredItems} />
                                                ) : (
                                                    <table className="report-table">
                                                        <thead>
                                                            <tr>
                                                                {activeTab === 'Reorder Report' && (
                                                                    <>
                                                                        <th>Product Name</th>
                                                                        <th>Available Quantity</th>
                                                                        <th>Reorder Quantity</th>
                                                                    </>
                                                                )}
                                                                {activeTab === 'Out of Stock Items' && (
                                                                    <>
                                                                        <th>Product Name</th>
                                                                        <th>Available Quantity</th>
                                                                    </>
                                                                )}
                                                                {(activeTab === 'Slow Moving Items' ||
                                                                    activeTab === 'Fast Moving Items') && (
                                                                        <>
                                                                            <th>Product Name</th>
                                                                            <th>Stock Out Count</th>
                                                                        </>
                                                                    )}
                                                                {(activeTab === 'Stock In Report' ||
                                                                    activeTab === 'Stock Out Report') && (
                                                                        <>
                                                                            <th>Product Name</th>
                                                                            <th>Quantity</th>
                                                                            <th>Details</th>
                                                                            <th>Action Type</th>
                                                                            <th>Date</th>
                                                                        </>
                                                                    )}
                                                                {activeTab === 'Expiry Report' && (
                                                                    <>
                                                                        <th>Product Name</th>
                                                                        <th>Expiry Date</th>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredItems.length > 0 ? (
                                                                filteredItems.map((item, index) => (
                                                                    <tr key={index}>
                                                                        {activeTab === 'Reorder Report' && (
                                                                            <>
                                                                                <td>{item.productName || 'N/A'}</td>
                                                                                <td>{item.initialQuantity !== undefined ? item.initialQuantity : '0'}</td>
                                                                                <td>{item.reorderQuantity !== undefined ? item.reorderQuantity : '0'}</td>
                                                                            </>
                                                                        )}
                                                                        {activeTab === 'Out of Stock Items' && (
                                                                            <>
                                                                                <td>{item.productName || 'N/A'}</td>
                                                                                <td>{item.initialQuantity !== undefined ? item.initialQuantity : 'N/A'}</td>
                                                                            </>
                                                                        )}
                                                                        {(activeTab === 'Slow Moving Items' || activeTab === 'Fast Moving Items') && (
                                                                            <>
                                                                                <td>{item.productName || 'N/A'}</td>
                                                                                <td>{item.totalStockOutQuantity || '0'}</td>
                                                                            </>
                                                                        )}
                                                                        {(activeTab === 'Stock In Report' || activeTab === 'Stock Out Report') && (
                                                                            <>
                                                                                <td>{item.productName || 'N/A'}</td>
                                                                                <td className="right-align">
                                                                                    {item.quantityChange || 'N/A'}
                                                                                </td>
                                                                                <td>{item.details || 'N/A'}</td>
                                                                                <td>{item.updateType || 'N/A'}</td>
                                                                                <td>
                                                                                    {item.timestamp
                                                                                        ? new Date(item.timestamp.seconds * 1000).toLocaleDateString()
                                                                                        : item.dateAdded
                                                                                            ? new Date(item.dateAdded.seconds * 1000).toLocaleDateString()
                                                                                            : 'N/A'}
                                                                                </td>
                                                                            </>
                                                                        )}
                                                                        {activeTab === 'Expiry Report' && (
                                                                            <>
                                                                                <td>{item.productName || 'N/A'}</td>
                                                                                <td>
                                                                                    {item.expiryDate
                                                                                        ? new Date(item.expiryDate.seconds * 1000).toLocaleDateString()
                                                                                        : 'N/A'}
                                                                                </td>
                                                                            </>
                                                                        )}
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="5">No data available for this report.</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}

                                                {/* Pagination Controls */}
                                                <div className="pagination-controls">
                                                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                                                        Previous
                                                    </button>
                                                    <span>
                                                        Page {currentPage} of {totalPages}
                                                    </span>
                                                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                                                        Next
                                                    </button>
                                                </div>
                                            </>
                                            <div className="export-button-container">
                                                <button onClick={handleExportPDF} className="export-button">
                                                    Export PDF <FaFilePdf />
                                                </button>
                                                <button onClick={handleExportExcel} className="export-button">
                                                    Export Excel <FaFileExcel />
                                                </button>
                                            </div>
                                        </div>
                                    )
                            )
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReportingPanel;
